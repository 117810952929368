
  /**
   * 问题反馈
   */
import axios from '../uitls/require';
export default {
   
  // 问题反馈列表
  feedbackList(data) {
    return axios.post('/admin/feedback/list', data)
  },
  // 问题反馈处理
  closeFeedback(id) {
    return axios.get('/admin/feedback/close?id=' + id) 
  },
  // 反馈信息
  feedbackInfo(id) {
    return axios.get('/admin/feedback/info?id=' + id) 
  }
}