<template>
  <div class="detail" v-if="hasResource('feedback_info')">
    <div class="cursor-pointer mg-b-58 width-107 height-46 mg-b-20 text-back" @click="back">
      <i class="iconfont fz-18 black">&#xe647;</i>
      <span class="back-text">返回</span>
    </div>
    <el-row v-loading="isLoading">
      <el-col :span="8">
        <div class="title">{{pageInfo.contact_name}}</div>
        <div class="email">{{pageInfo.contact_email}}</div>
        <div class="problem">
          <div class="height-23 fz-16">
            <span>问题单号</span>
            <span class="mg-l-18">{{pageInfo.feedback_number}}</span>
          </div>
          <div class="height-23 fz-16 mg-tb-16">
            <span>提交时间</span>
            <span class="mg-l-18">{{pageInfo.created_at}}</span>
          </div>
          <div class="height-23 fz-16">
            <span>问题类型</span>
            <span
              class="mg-l-18"
            >{{pageInfo.type == 3 ? '其它问题' : pageInfo.type == 2 ? '注册问题': '充值问题'}}</span>
          </div>
        </div>
        <div class="status">{{pageInfo.status == 1 ? '已关闭' : '未关闭'}}</div>
        <div class="fz-14" v-if="pageInfo.status == 1 ">
          <div class="mg-t-24">处理人</div>
          <div class="mg-t-8">
            <span>{{pageInfo.processed_by.username}}</span>
            <span class="mg-l-18">{{pageInfo.processed_by.email}}</span>
          </div>
          <div class="mg-t-20">处理时间</div>
          <div class="mg-t-8">{{pageInfo.solved_at}}</div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="describe">
          <!-- <div class="fz-18">问题描述</div> -->
          <div class="sub-title">标题：{{pageInfo.title}}</div>
          <div
            class="fz-14 con-text ql-snow ql-editor content-detail"
            style="height: auto;padding:0px;"
            v-html="pageInfo.content"
          ></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import api from "../../../api/feedback";
export default {
  data() {
    return {
      isLoading: false,
      pageInfo: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let id = this.$route.query.id;
      this.isLoading = true;
      api.feedbackInfo(id).then((res) => {
        this.isLoading = false;
        if (res.data.code === 10200) {
          this.pageInfo = res.data.data;
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    back() {
      this.$router.push("/customers/feedbacks");
      this.msgBus.$emit("feedList");
    },
  },
};
</script>
<style lang="less">
@import "index.less";
</style>